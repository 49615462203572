import {AnimatePresence, motion} from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import React from "react";
import {Card, Divider} from "@mui/material";
import Heading1 from "../../components/text/Heading1";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading2 from "../../components/text/Heading2";
import VideoToolTip from "../../VideoToolTip";

interface FinalizeAppSetupProps {
    onBackPressed: () => void;
    onNextPressed: () => void;
    stepVisible: boolean;
}

const SetUpAppStep = ({onBackPressed, onNextPressed, stepVisible}: FinalizeAppSetupProps) => {
    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{opacity: 0, scaleY: 0}}
                        animate={stepVisible ? {opacity: 1, scaleY: 1, transition: {delay: 0.6, duration: 0.2}} : {}}
                        exit={{opacity: 0, scaleY: 0}}
                        transition={{duration: 0.1}}
                        className={"flex items-center justify-center"}
                    >
                        <Card
                            className={"flex flex-col items-center md:items-start text-center md:text-left max-h-[650px] w-full max-w-4xl p-6 bg-white shadow-md"}>
                            <div className="w-full h-[70vh] overflow-y-auto p-10">
                                <Heading1 leftAligned={true}>Opprett app</Heading1>
                                <HeadingDescriptor withoutMargin={true} leftAligned={true}>Vi kan nå opprette en app som
                                    gjør at enthemed kommuniserer direkte med nettbutikken din</HeadingDescriptor>
                                <VideoToolTip
                                    videoSrc={"https://fast.wistia.com/embed/medias/z1bchesh99"}
                                    infoText="Følg stegene i denne videoen for å komme igang,"
                                />

                                <div className="border-b border-gray-200 mt-10"></div>
                                <div className="mt-14 w-full">

                                    <p className="text-lg text-left mb-4 mt-12">
                                        <Heading2>Steg 5</Heading2>
                                        Klikk på <strong>“create an app”</strong>
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg5.jpg"} alt="Steg 5"/>


                                    <p className="text-lg text-left mt-12 mb-4">
                                        <Heading2>Steg 6</Heading2>
                                        Gi appen et navn, f.eks <strong>“Enthemed custom app”</strong> så klikk <strong>“Create
                                        app”</strong>
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg6.jpg"} alt="Steg 6"/>


                                    <p className="text-lg text-left mb-4 mt-12">
                                        <Heading2>Steg 7</Heading2>
                                        Klikk på <strong>“configure admin scopes”</strong>
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg7.jpg"} alt="Steg 7"/>

                                    <p className="text-lg text-left mb-4 mt-12">
                                        <Heading2>Steg 8</Heading2>
                                        I tabellen under vil du finne en liste med <strong>“access scopes”</strong>,
                                        klikk på boksene for å markere dem
                                        <p className="text-xl text-red-500 text-left mt-4"><strong>VIKTIG: Her må du
                                            markere alle boksene på den måten sikrer du at appen fungerer som den
                                            skal</strong></p>
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg8.jpg"} alt="Steg 8"/>

                                    <p className="text-lg text-left mb-4 mt-12">
                                        <Heading2>Steg 9</Heading2>
                                        Når du har huket av på alle tilganger, klikk på <strong>“Save”</strong> for å
                                        lagre endringene
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg9.jpg"} alt="Steg 9"/>

                                    <div className="border-b border-gray-200 mt-10"></div>

                                    <div className="flex justify-center gap-10 mt-10">
                                        <SecondaryButton onClickEvent={onBackPressed}>Gå tilbake</SecondaryButton>
                                        <MainButton onClickEvent={onNextPressed}>Gå videre</MainButton>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default SetUpAppStep;
