import * as React from 'react';
import List from '@mui/material/List';
import {
    UsersIcon,
    HomeIcon,
    Cog8ToothIcon,
    LightBulbIcon,
    BriefcaseIcon,
    BanknotesIcon,
    BoltIcon,
    BuildingStorefrontIcon,
} from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SidebarListItem from './SidebarListItem';
import { motion } from 'framer-motion';
import { IconButton } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import {useStoreProvider} from "../../providers/StoreProvider";
import DropdownMenu, {MenuOption} from "../input-fields/DropdownMenu";
import {FormProvider, useForm} from "react-hook-form";

interface SidebarProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedSidebarItem: string;
    setSelectedSidebarItem: Dispatch<SetStateAction<string>>;
    className?: string;
}

export default function Sidebar(props: SidebarProps) {
    const [storesMenuOptions, setStoresMenuOptions] = useState<MenuOption[]>([])
    const {store, stores, switchStore} = useStoreProvider()
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        stores && setStoresMenuOptions(stores.map(store => {return {title: store.name + ".myshopify.com", id: store.id}}))
    }, [store]);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const toggleDrawer = () => {
        props.setOpen(!props.open);
    };

    const methods = useForm();

    return (
        <div className={props.className}>
            <motion.div
                initial={isMobile ? { width: 0 } : { width: '80px' }}
                animate={props.open ? { width: '350px' } : isMobile ? { width: 0 } : { width: '80px' }}
                transition={{ duration: 0.2 }}
                className="h-screen flex flex-col gap-y-5 overflow-y-auto bg-white shadow-md"
            >
                <div className="flex justify-center my-3">
                    {props.open ? (
                        <img className="h-9 w-auto mt-5" src="/Enthemed-1x.png" />
                    ) : (
                        <img className="h-9 w-auto mt-5" src="/Enthemed-icon-1.png" />
                    )}
                </div>
                <div className="flex justify-center">
                    <IconButton onClick={toggleDrawer}>
                        {props.open ? (
                            <ChevronLeftIcon className="h-6 w-auto" />
                        ) : (
                            <ChevronRightIcon className="h-6 w-auto" />
                        )}
                    </IconButton>
                </div>

                <List className="flex-1">
                    <FormProvider {...methods}>
                        <form className={"flex md:hidden ml-auto -mt-8 mb-5 w-full px-5"}>
                            <DropdownMenu
                                name={"store"}
                                label={"Butikk"}
                                options={storesMenuOptions}
                                defaultValue={store?.id}
                                onChange={(menuOption) => stores && switchStore!(stores.filter(b => b.id == menuOption?.id)[0])}
                                hideEmpty={true}
                                icon={"/shopify.svg"}
                            />
                        </form>
                    </FormProvider>
                    <SidebarListItem
                        selectedSidebarItem={props.selectedSidebarItem}
                        setSelectedSidebarItem={props.setSelectedSidebarItem}
                        setOpen={props.setOpen}
                        link="/"
                        icon={<HomeIcon className="h-8 w-10 shrink-0" />}
                        sidebarVisible={props.open}
                        text="Hjem"
                    />
                    <SidebarListItem
                        selectedSidebarItem={props.selectedSidebarItem}
                        setSelectedSidebarItem={props.setSelectedSidebarItem}
                        setOpen={props.setOpen}
                        link="/themes"
                        icon={<BuildingStorefrontIcon className="h-8 w-10 shrink-0" />}
                        sidebarVisible={props.open}
                        text="Temaer"
                    />
                    <SidebarListItem
                        selectedSidebarItem={props.selectedSidebarItem}
                        setSelectedSidebarItem={props.setSelectedSidebarItem}
                        setOpen={props.setOpen}
                        link="/power-ups"
                        icon={<BoltIcon className="h-8 w-10 shrink-0" />}
                        sidebarVisible={props.open}
                        text="Power ups"
                    />
                </List>
                <div className="mt-auto">
                    <SidebarListItem
                        selectedSidebarItem={props.selectedSidebarItem}
                        setSelectedSidebarItem={props.setSelectedSidebarItem}
                        setOpen={props.setOpen}
                        link="/settings"
                        sidebarVisible={props.open}
                        text="Innstillinger"
                        icon={<Cog8ToothIcon className="h-8 w-10 shrink-0" />}
                    />
                </div>
            </motion.div>
        </div>
    );
}
