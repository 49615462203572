import React from 'react';
import {motion} from 'framer-motion'; // Import Framer Motion
import Heading2 from "../../../components/text/Heading2";
import MainButton from "../../../components/buttons/MainButton";

interface ThemeGridElementProps {
    imageSrc: string;
    title: string;
    description: string;
    bulletPoints?: string[];
    buttonText: string;
    onButtonClick: () => void;
    badgeTexts?: string[]; // Update to accept an array of badge texts
}

const ThemeGridElement = ({
                              imageSrc,
                              title,
                              description,
                              buttonText,
                              onButtonClick,
                              badgeTexts // Destructure badgeTexts prop
                          }: ThemeGridElementProps) => {
    return (
        <div
            className="flex flex-col md:flex-col lg:flex-row items-center text-left shadow-lg p-6 md:p-10 rounded-3xl relative bg-white w-full">
            <div className="w-full lg:w-1/2 mb-6 lg:mb-0 lg:mr-6">
                {/* Wrap the image with a motion.div */}
                <motion.div
                    whileHover={{scale: 1.02}} // More subtle zoom effect on hover
                    transition={{type: "tween", duration: 0.4, ease: "easeInOut"}} // Smooth, slow pan effect
                    className="rounded-lg overflow-hidden"
                >
                    <img src={imageSrc} alt={title} className="w-full h-full object-cover"/>
                </motion.div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-start items-start">
                <Heading2 className="text-3xl font-bold mb-4 mt-2">{title}</Heading2>
                <p className="text-gray-500 text-left text-md">
                    {description}
                </p>
                {badgeTexts && badgeTexts.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-4">
                        {badgeTexts.map((badgeText, index) => (
                            <span key={index}
                                  className="inline-flex items-center rounded-full bg-gradient-to-r from-blue-50 to-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                                {badgeText}
                            </span>
                        ))}
                    </div>
                )}
                <MainButton onClickEvent={onButtonClick}
                            className="mt-6 bg-gradient-to-r from-purple-500 to-blue-500">{buttonText}</MainButton>
            </div>
        </div>
    );
};

export default ThemeGridElement;
