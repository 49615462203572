import React, {useEffect, useState} from 'react';
import Heading2 from "./components/text/Heading2";
import MainButton from "./components/buttons/MainButton";
import {PowerUpButtonState} from "./enums/PowerUpButtonState";
import DestructiveButton from "./components/buttons/DestructiveButton";
import {PowerUpDTO} from "./types/PowerUpDTO";
import {powerUpCanBeCustomized} from "./pages/power-ups/PowerUpMapper";
import {usePowerUpsProvider} from "./providers/PowerUpsProvider";
import {Tooltip} from "@mui/material";
import PowerUpPreview from "./pages/power-ups/components/PowerUpPreview";
import {useStoreProvider} from "./providers/StoreProvider";
import RegisterOrIntegrateStorePopup from "./components/modals/RegisterOrIntegrateStorePopup";

interface PowerUpGridElementProps {
    powerUpDTO: PowerUpDTO;
    onButtonClick: () => void;
}

const PowerUpGridElement = ({powerUpDTO, onButtonClick}: PowerUpGridElementProps) => {
    const [previewPowerUp, setPreviewPowerUp] = useState<boolean>(false);

    const {store, showRegisterStorePrompt, showIntegrateStorePrompt} = useStoreProvider()

    const {powerUps} = usePowerUpsProvider();
    const [powerUpButtonState, setPowerUpButtonState] = React.useState<PowerUpButtonState | undefined>(undefined)
    const [notCompatibleWithText, setNotCompatibleWithText] = useState<string | undefined>()

    useEffect(() => {
        if (powerUpDTO.enabled) {
            setPowerUpButtonState(powerUpCanBeCustomized(powerUpDTO) ? PowerUpButtonState.CUSTOMIZE : PowerUpButtonState.DEACTIVATE)
            return
        }

        let notCompatibleWith = ""
        for (let i = 0; i < powerUps!.length; i++) {
            let checkPowerUp = powerUps![i]
            if (!checkPowerUp.enabled) {
                continue
            }

            if (powerUpDTO.notCompatibleWith) {
                for (let b = 0; b < powerUpDTO.notCompatibleWith.length; b++) {
                    if (powerUpDTO.notCompatibleWith[b] === checkPowerUp.id) {
                        if (notCompatibleWith !== "") {
                            notCompatibleWith += ", "
                        }

                        notCompatibleWith += powerUps?.find(f => f.id == powerUpDTO.notCompatibleWith[b])!.title
                        break
                    }
                }
            }
        }

        if (notCompatibleWith !== "") {
            setNotCompatibleWithText(notCompatibleWith)
            setPowerUpButtonState(PowerUpButtonState.NOT_COMPATIBLE)
            return;
        }

        setPowerUpButtonState(PowerUpButtonState.ACTIVATE)
    }, [powerUpDTO, powerUps]);

    return (
        <div
            className="flex flex-col text-center md:text-left shadow-lg p-6 md:p-10 rounded-3xl relative bg-white w-full">
            <PowerUpPreview
                previewPowerUp={previewPowerUp ? powerUpDTO : undefined}
                cancelClicked={() => setPreviewPowerUp(false)}
            />

            {powerUpDTO.enabled && (
                <div
                    className="absolute top-0 left-0 flex items-center bg-purple-600 text-white py-1 px-2 rounded-tl-xl rounded-br-xl">
                    <span className="text-green-500 mr-2">●</span>
                    Aktiv
                </div>
            )}
            <div className="flex flex-col items-center md:items-start mb-4 mt-8">
                <img src={powerUpDTO.iconUrl} alt="icon" className="w-24 h-24 text-purple-500"/>
            </div>
            <div className="flex flex-col items-center md:items-start flex-1">
                <Heading2 className="text-center md:text-left">{powerUpDTO.title}</Heading2>
                <p className="text-gray-500 text-center md:text-left mt-2">
                    {powerUpDTO.shortDescription}
                </p>
            </div>
            <div className="flex justify-center md:justify-start w-full mt-6 gap-5">
                <span onClick={() => setPreviewPowerUp(true)} className="text-main-color mt-2 cursor-pointer hover:text-hover-color transition-all">Forhåndsvisning</span>

                {store ? (
                    <>
                        {store.credentialsForStoreRegistered ? (
                            <>
                                {/* Activate or customize */}
                                {store && (powerUpButtonState == PowerUpButtonState.ACTIVATE || powerUpButtonState == PowerUpButtonState.CUSTOMIZE) && (
                                    <MainButton
                                        onClickEvent={onButtonClick}>{powerUpButtonState == PowerUpButtonState.ACTIVATE ? "Aktiver" : "Tilpass"}</MainButton>
                                )}

                                {/* Deactivate */}
                                {powerUpButtonState == PowerUpButtonState.DEACTIVATE && (
                                    <DestructiveButton onClickEvent={onButtonClick}>Deaktiver</DestructiveButton>
                                )}

                                {/* Power-up not compatible */}
                                {powerUpButtonState == PowerUpButtonState.NOT_COMPATIBLE && (
                                    <MainButton
                                        disabled={true}
                                    >
                                        <Tooltip title={powerUpDTO.title + " er ikke kompatibel med " + notCompatibleWithText}>
                                            <span>Utilgjengelig</span>
                                        </Tooltip>
                                    </MainButton>
                                )}
                            </>
                        ) : (
                            <>
                                <MainButton
                                    onClickEvent={() => showIntegrateStorePrompt!("Integrer med Shopify", (
                                        <>
                                            <span>Du må installere Enthemed appen i Shopify før du kan ta i bruk power-ups.</span>
                                        </>
                                    ))}
                                >
                                    Aktiver
                                </MainButton>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {/* If the user has not configured a store */}
                        {!store && (
                            <MainButton
                                onClickEvent={() => showRegisterStorePrompt!("Registrer nettbutikken", (
                                    <>
                                        <span>Du må registrere nettbutikken din før du kan bruke Enthemed power-ups.</span>
                                    </>
                                ))}
                            >
                                Aktiver
                            </MainButton>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PowerUpGridElement;
