import React from "react";
import {PopupComponent} from "../../../components/modals/PopupComponent";
import MainButton from "../../../components/buttons/MainButton";
import Heading1 from "../../../components/text/Heading1";

interface ThemeDetailsPopupProps {
    handleCancelClick: () => void;
    isVisible: boolean;
    handleDownloadClick: () => void;
    imageSrc: string;
    title: string;
    previewLink: string;
}

const ThemeDetailsPopup: React.FC<ThemeDetailsPopupProps> = ({ handleCancelClick, isVisible, handleDownloadClick, imageSrc, title, previewLink }) => {
    return (
        <PopupComponent closePopup={handleCancelClick} isVisible={isVisible}>
            <div className="text-center">
                <Heading1 className={"mb-8"}>{title}</Heading1>
                <img src={imageSrc} alt={title} className="mb-8" />
                <div className={"flex flex-col"}>
                    <MainButton
                        className={"mb-4"}
                        onClickEvent={handleDownloadClick}
                    >
                        Last ned
                    </MainButton>
                </div>

            </div>
        </PopupComponent>
    );
};

export default ThemeDetailsPopup;
