import React from "react";
import {getBackendURL, isStagingEnv} from "../../utils/EnvironmentsManager";

const FacebookButton = () => {
    return (
        <>
            {isStagingEnv() && (
                <a
                    href={getBackendURL() + '/api/v1/oauth2/authorization/facebook'}
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="896" cy="896" r="896" fill="#1877F2"/>
                        <g transform="scale(0.90) translate(25, 500)">
                            <path
                                d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"
                                fill="white"
                            />
                        </g>
                    </svg>
                    <span className="text-sm font-semibold leading-6">Facebook</span>
                </a>
            )}
        </>
    );
};

export default FacebookButton;
