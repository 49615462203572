import {XMarkIcon} from "@heroicons/react/24/outline";
import MainButton from "../../../components/buttons/MainButton";
import {useCurrentUser} from "../../../providers/UserProvider";
import {useEffect, useState} from "react";
import {OnboardingStatus} from "../../../enums/OnboardingStatus";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import {toast} from "react-hot-toast";
import {useStoreProvider} from "../../../providers/StoreProvider";

const OnboardingNotification = () => {
    const {currentUser, refreshUser} = useCurrentUser()
    const {store} = useStoreProvider()
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        if (currentUser && currentUser.onboardingStatus == OnboardingStatus.SKIPPED) {
            setVisible(true)
        }
    }, [currentUser]);

    const closePopup = () => {
        setVisible(false)
    }

    const finishNowPressed = () => {
        axios.post(getBackendURL() + "/api/v1/user/unskip-onboarding").then(result => {
            refreshUser && refreshUser()
        }).catch(error => {
            toast.error("En feil har dessverre oppstått")
        })
    }

    return (
        <>
            {visible && (
                <div className={"relative bg-gradient-to-br from-purple-50 to-indigo-100 rounded-xl p-4 mx-4 -mt-20 mb-10 border-main-color border-4 shadow-xl z-30 md:max-w-[600px] md:mx-auto"}>
                    <button
                        onClick={closePopup}
                        className={"absolute right-2 top-2"}
                    >
                        <XMarkIcon className={"w-6 h-6"}/>
                    </button>
                    <h1 className={"font-bold md:text-2xl md:mb-1"}>

                        Du har ikke fullført onboardingen
                    </h1>
                    <p>
                        For å kunne bruke Enthemed temaer og power-ups må du fullføre onboardingen. Dette sikrer at
                        Enthemed power-ups fungerer som de skal.
                    </p>
                    <div className={"flex justify-center mt-5"}>
                        <MainButton onClickEvent={finishNowPressed}>
                            Fullfør nå
                        </MainButton>
                    </div>
                </div>
            )}
        </>
    )
}

export default OnboardingNotification